<template>
  <div className="animated fadeIn">

      <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table">

          <template slot="actions" slot-scope="props">
            <b-button v-show="hasFullAccess" variant="secondary" :to="{ name: 'Complaint', params: { id: props.row.id } }">View</b-button>
          </template>

      </v-server-table>
      
  </div>
</template>

<script>
  import Vue from 'vue'
  import {ServerTable} from 'vue-tables-2'
  import axios from '../../../shared/axios/auth'

  Vue.use(ServerTable)

  export default {
    name: 'DataTable',
    components: {
      ServerTable
    },
    data: function () {
      return {
        get_document: false,
        columns: [
          'reference', 'complaint_type', 'complaint_sub_type', 'customer', 'status', 'actions'
        ],
        data: [],
        checkedData: [],
        fields: [
        ],
        options: {
          requestFunction: (data) => {
              return axios.get('/complaints/', {
                  params: data
              }).catch(error => {
                // Unauthorised
                if (error.response.status === 401) {
                  this.$store.dispatch('logout');
                }
                console.log('quotes error');
                console.log(error.response)
              });
          },
          responseAdapter: function(resp) {
            var data = this.getResponseData(resp);
            return { data: data.data.data, count: data.data.pagination.total }
          },
          headings: {
            groups: 'Permissions'
          },
          sortable: [],//['reference', 'complaint_type', 'complaint_sub_type', 'customer', 'status',],
          filterable: [], //['reference', 'complaint_type', 'complaint_sub_type', 'customer', 'status',],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default'
      }
    },
    methods: {
      downloadCSV() {

        this.get_document = true;

        axios({
          url: '/users/export/',
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', "user-export.csv");
          document.body.appendChild(link);
          link.click();
          this.get_document = false;
        }).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
      },
      getUsers() {
        axios.get('/complaints/').then(
          response => (
            this.data = response.data
          )
        ).catch(error => {

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        return this.data
      },
    },
    computed: {
      hasAccess() {

        let access = ['full_admin', 'data_management'];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      }
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getUsers()
        );

      }
    },
    mounted() {
      this.getUsers()
    }

  };
</script>

<style>

  #dataTable  table {
    background-color: #fff;
  }
  .form-inline label {
      justify-content: left;

  }


</style>
